<template>
  <div class="d-flex flex-row">
    <filter-params
      v-model="computedFilter"
      :readonly="readonly"
      auto-filter-type="invoices"
      :label="$t('t.Payments')"
    />
    <v-checkbox
      hide-details
      v-model="computedFees"
      :label="$t('t.Fees')"
      color="secondary"
      class="no-top-margin"
    />
  </div>
</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    FilterParams: () => import('./filter-params/filter-params')
  },
  computed: {
    computedFees: {
      get () { return this.dataFees },
      set (v) {
        this.dataFees = v
        this.dataDocumentEmit()
      }
    },
    computedFilter: {
      get () { return this.dataFilter },
      set (v) {
        this.dataFilter = v
        this.dataDocumentEmit()
      }
    }
  },
  methods: {
    emitDocument () {
      const doc = { fees: this.dataFees, paymentFilter: this.dataFilter }

      if (!this.lodash.isEqual(this.document, doc)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  data () {
    return {
      dataFilter: {},
      dataFees: false,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument)
    }
  },
  props: {
    document: Object,
    readonly: Boolean,
    targetDocTy: String
  },
  watch: {
    document: {
      immediate: true,
      handler (v) {
        const bal = this.lodash.cloneDeep(v)
        this.dataFilter = bal?.paymentFilter ?? {}
        this.dataFees = bal?.fees ?? false
        this.dataDocumentEmit()
      }
    }
  }
}
</script>
<style lang="stylus">
.no-top-margin
  margin-top 0
</style>
